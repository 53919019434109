<template>
	<tr>
		<td>
			<img :src="entry.img" width="100%" />
		</td>
		<td>
			<b-input
				v-model="entry.description"
				@input="handleInput"
				@submit="handleSubmit"
			></b-input>
		</td>
		<td>
			<b-button variant="outline-secondary" @click="moveUp">
				<b-icon icon="arrow-up"></b-icon>
			</b-button>
			<b-button variant="outline-secondary" @click="moveDown">
				<b-icon icon="arrow-down"></b-icon>
			</b-button>
			<b-button variant="outline-primary" @click="onPostClick">
				Post
			</b-button>
			<b-button variant="outline-danger" @click="onDeleteClick">
				<b-icon-trash />
			</b-button>
			<b-badge :variant="entry.status != 'error' ? 'info' : 'danger'">{{
				entry.status
			}}</b-badge>
			<b-progress :value="loaded" :max="total" />
		</td>
	</tr>
</template>
<script>
import { cacheService, entryService, fbService } from '../services'
export default {
	props: {
		entry: Object,
	},
	data() {
		return {
			loaded: 0,
			total: 0,
		}
	},
	mounted() {
		this.entry.postAsync = this.postAsync
	},
	methods: {
		saveEntry() {
			entryService.setEntry({
				id: this.entry.id,
				description: this.entry.description,
				img: this.entry.img,
				status: this.entry.status,
			})
		},
		handleInput(e) {
			this.entry.description = e
			this.saveEntry()
		},
		handleBlur() {},
		handleSubmit(evt) {
			console.log(evt)
		},
		onBlur() {
			this.$emit('set-description', this)
		},
		onSubmit() {},
		async onPostClick() {
			await this.postAsync()
		},
		async postAsync() {
			this.entry.status = 'posting'
			try {
				const page = cacheService.getPage()
				const post = cacheService.getPost()
				const response = await fbService.comment(
					page.access_token,
					post.id,
					this.entry.description,
					this.entry.img,
					(e) => {
						this.loaded = e.loaded
						this.total = e.total
					}
				)
				console.log(response)
				this.entry.status = 'posted'
				this.saveEntry()
				return true
			} catch {
				this.entry.status = 'error'
				return false
			}
		},
		post() {
			console.log('post-en' + this.index)
			return this.index
		},
		onDeleteClick() {
			this.$emit('delete', this.index)
		},
		moveUp() {
			this.$emit('move-up', this.index)
		},
		moveDown() {
			this.$emit('move-down', this.index)
		},
	},
}
</script>
