<template>
	<div>
		<table class="table col-12" v-columns-resizable>
			<thead class="table-head">
				<tr>
					<th width="25%" class="table-resize-bar">Picture</th>
					<th width="65%" class="table-resize-bar">Description</th>
					<th width="10%">Status</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(entry, index) in entries">
					<Entry
						:key="entry.id"
						@delete="() => handleDelete(entry.id)"
						@move-up="() => handleMoveUp(index)"
						@move-down="() => handleMoveDown(index)"
						:entry="entry"
					/>
				</template>
				<tr class="new-post-tr">
					<td colspan="3">
						<div
							class="new-post"
							@drop.prevent="onDrop"
							@dragover.prevent
							@dragenter.prevent="onDragEnter"
							@dragleave.prevent="onDragLeave"
						>
							<div :class="dropClass">Drop image here</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { cacheService, entryService, fbService } from '../services'
import Entry from '@/components/Entry.vue'
import { CancellationToken } from '../services'
/* eslint-disable no-unused-vars */
import { Entry as ServiceEntry, PreImportEntry } from '../services'
/* eslint-enable no-unused-vars */


export default {
	props: {
		token: CancellationToken,
	},
	data() {
		return {
			/**
			 * @type {Array<ServiceEntry>}
			 */
			entries: [],
			dropClass: 'drop-area',
		}
	},
	async created() {
		this.entries = await entryService.getEntries()
		console.log(this.entries)
	},
	mounted() {
		this.token.onPost = this.post
		this.token.onDelete = this.onDelete
	},
	components: {
		Entry,
	},
	methods: {
		/**
		 * @returns {Promise<PreImportEntry>}
		 */
		loadImage(file) {
			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = async (f) => {
					const src = f.target.result
					/**
					 * @type {PreImportEntry}
					 */
					let data = {
						img: src,
						description: '',
						status: 'ready',
					}
					resolve(data)
				}
				reader.onerror = reject
				reader.readAsDataURL(file)
			})
			return promise
		},
		loadJson(file) {
			const reader = new FileReader()
			reader.onload = async (f) => {
				const src = f.target.result
				const entries = JSON.parse(src.toString())
				/**
				 * @type {Array<Promise<PreImportEntry>>}
				 */
				const promises = entries.map(async (i) => {
					/**
					 * @type {PreImportEntry}
					 */
					let data = {
						img: i.img,
						description: i.description,
						status: 'ready',
					}
					return data
				})
				const preimports = await Promise.all(promises)
				for (const preimport of preimports) {
					await this.pushEntry(preimport)
				}
			}
			reader.readAsText(file)
		},
		loadTxt(file) {
			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = async (f) => {
					const src = f.target.result
					const lines = src
						.toString()
						.split('\n')
						.map((l) => l.trim())
					for (let i = 0; i < this.entries.length; i++) {
						const element = this.entries[i]
						if (i < lines.length) {
							element.description = lines[i]
						}
					}
				}
				reader.onerror = reject
				reader.readAsText(file)
			})
			return promise
		},
		/**
		 * @param {PreImportEntry} preimportEntry
		 */
		async pushEntry(preimportEntry) {
			const id = await entryService.addEntry(preimportEntry)
			const entry = await entryService.getEntry(id)
			this.entries.push(entry)
		},
		//drop imagefile and save
		async onDrop(evt) {
			this.dropClass = 'drop-area'
			let files = evt.dataTransfer.files
			console.log(files)
			for (let i = 0; i < files.length; i++) {
				/**
				 * @type {File}
				 */
				const file = files[i]
				if (
					file.type == 'image/png' ||
					file.type == 'image/jpg' ||
					file.type == 'image/jpeg'
				) {
					const preImport = await this.loadImage(file)
					this.pushEntry(preImport)
				} else if (file.type == 'application/json') {
					this.loadJson(file)
				} else if (file.type == 'text/plain') {
					this.loadTxt(file)
				} else {
					console.log(file)
				}
			}
		},
		onDragEnter() {
			this.dropClass = 'drop-area drop-area-enter'
		},
		onDragLeave() {
			this.dropClass = 'drop-area'
		},
		handleInput(input, index) {
			this.datas[index] = input
		},
		handleDelete(id) {
			entryService.removeEntry(id)
			const index = this.entries.findIndex((i) => i.id === id)
			this.entries.splice(index, 1)
		},
		async post() {
			this.token.status = 'running'
			await this.reloadPageToken()
			let isCancel = false
			console.log(this.entries)
			for (const index in this.entries) {
				const element = this.entries[index]
				console.log({
					action: 'post',
					entry: element,
					index,
				})
				let success = false
				do {
					success = await element.postAsync()
					await this.sleep(5000)
					if (this.token.isCancel) {
						isCancel = true
						this.token.isCancel = false
					}
					if (isCancel) break
				} while (!success)
				if (isCancel) break
			}
			this.token.status = 'success'
		},
		handleMoveUp(index) {
			if (index > 0) {
				const entry1 = this.entries[index]
				const entry2 = this.entries[index - 1]
				const id1 = entry1.id
				const id2 = entry2.id
				entry1.id = id2
				entry2.id = id1
				this.entries.splice(index, 1)
				this.entries.splice(index - 1, 0, entry1)
				entryService.setEntry(entry1)
				entryService.setEntry(entry2)
			}
		},
		handleMoveDown(index) {
			if (index < this.entries.length - 1) {
				const entry1 = this.entries[index]
				const entry2 = this.entries[index + 1]
				const id1 = entry1.id
				const id2 = entry2.id
				entry1.id = id2
				entry2.id = id1
				console.log(entry1)
				console.log(entry2)
				this.entries.splice(index, 1)
				this.entries.splice(index + 1, 0, entry1)
				entryService.setEntry(entry1)
				entryService.setEntry(entry2)
			}
		},
		onDelete() {
			console.log('delete all')
			this.entries = []
		},
		async reloadPageToken() {
			const accessToken = fbService.getAccessToken()
			const pageCache = cacheService.getPage()
			const accountResponse = await fbService.getMeAccounts(accessToken)
			const page = accountResponse.data.filter(
				(i) => i.id == pageCache.id
			)[0]
			cacheService.setPage({
				id: page.id,
				name: page.name,
				access_token: page.access_token,
			})
		},
		sleep(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms))
		},
	},
}
</script>
<style>
#postlist {
	width: 100%;
}
.table-resize-bar {
	border-right: 2px solid #0a0a0a;
}
.new-post-tr {
	background-color: #dfdfdf;
}
.new-post {
	min-height: 250px;
}
.drop-area-enter {
	background-color: #c5c5c5;
}
.drop-area {
	margin: 15px;
	border: 4px dashed #0a0a0a;
	min-height: 250px;
	justify-content: center;
	padding: auto;
	border-radius: 30px;
}
.description {
	width: 100%;
}
.table-head {
	background-color: #dfdfdf;
}
</style>
