<template>
	<div class="main header">
		<template v-if="!isLogin">
			<b-button variant="primary" @click="login">
				Login With Facebook
			</b-button>
		</template>
		<template v-if="isLogin">
			<b-button variant="outline-primary">
				{{ this.user }}
			</b-button>
			<facebook-page />
			<facebook-post />
			<b-button variant="outline-secondary" @click="logout">
				Logout
			</b-button>
			<b-button class="ml-1" variant="primary" @click="handlePostClick">{{
				this.token.status == 'running' ? 'Stop' : 'Post'
			}}</b-button>
		</template>
		<b-button
			class="ml-1"
			variant="outline-primary"
			@click="handleExportClick"
			>Export</b-button
		>
		<b-button
			class="ml-1"
			variant="outline-danger"
			@click="handleDeleteClick"
			>Delete</b-button
		>
	</div>
</template>
<script>
import { fbService, cacheService, CancellationToken } from '../services'
/* eslint-disable no-unused-vars */
import { PageData, PostData } from '../services'
import { PageCache, PostCache } from '../services'
/* eslint-enable no-unused-vars */
import FacebookPage from './FacebookPage.vue'
import FacebookPost from './FacebookPost.vue'

export default {
	components: { FacebookPost, FacebookPage },
	props: {
		token: CancellationToken,
	},
	data() {
		return {
			isLogin: false,
			/**
			 * @type {string}
			 */
			user: null,
			entries: [],
		}
	},
	created() {
		const authResponse = fbService.getAuthResponse()
		this.isLogin = authResponse != null
		if (this.isLogin) {
			this.updateFromCache()
		}
	},
	methods: {
		async login() {
			try {
				const result = await fbService.login()
				this.isLogin = result.status == 'connected'
				if (this.isLogin) {
					this.updateMeStatus(result.authResponse.accessToken)
					this.updatePageStatus(result.authResponse.accessToken)
				}
			} catch (err) {
				this.$emit('push-error', err)
			}
		},

		logout() {
			fbService.logout()
			this.isLogin = false
			this.user = null
			cacheService.clear()
		},

		updateFromCache() {
			this.user = cacheService.getUserName()
		},

		/**
		 * @param {string} accessToken
		 */
		async updateMeStatus(accessToken) {
			const meResponse = await fbService.getMeName(accessToken)
			this.user = meResponse.name
			cacheService.setUserName(meResponse.name)
		},

		/**
		 * @param {string} accessToken
		 */
		async updatePageStatus(accessToken) {
			const accountResponse = await fbService.getMeAccounts(accessToken)
			this.pages = accountResponse.data
			const selectPage = accountResponse.data[0]
			this.page = selectPage
			cacheService.setPage({
				id: selectPage.id,
				name: selectPage.name,
				access_token: selectPage.access_token,
			})
			await this.updatePostStatus(this.page.access_token, this.page.id)
		},

		async updatePostStatus(accessToken, pageId) {
			const response = await fbService.getPosts(accessToken, pageId)
			this.posts = response.data
			const selectPost = response.data[0]
			this.post = selectPost
			cacheService.setPost({
				id: selectPost.id,
				message: selectPost.message,
			})
		},
		handlePostClick() {
			if (this.token.status !== 'running') {
				console.log('post')
				this.token.post()
			} else {
				console.log('cancel')
				this.token.cancel()
			}
		},
		handleExportClick() {
			this.token.export()
		},
		handleDeleteClick() {
			if (confirm('Delete all entries')) {
				this.token.delete()
			}
		},
	},
}
</script>
<style>
.main {
	position: sticky;
	top: 0px;
	background-color: #fafafa;
	z-index: 1;
}
</style>
