import { entryService } from './entryService'

export class CancellationToken {
	constructor() {}
	isCancel = false
	/**
	 * @type {('unknown'|'running'|'success')}
	 */
	status = 'unknown'
	onPost = null
	onDelete = null
	cancel() {
		this.isCancel = true
	}
	post() {
		this.onPost()
	}
	async export() {
		let entries = await entryService.getEntries()
		let date = 'file'
		download(`${date}.json`, JSON.stringify(entries))
	}
	async delete() {
		await entryService.removeAll()
		this.onDelete()
	}
}

function download(filename, text) {
	var element = document.createElement('a')
	element.setAttribute(
		'href',
		'data:application/json;charset=utf-8,' + encodeURIComponent(text)
	)
	element.setAttribute('download', filename)

	element.style.display = 'none'
	document.body.appendChild(element)

	element.click()

	document.body.removeChild(element)
}
